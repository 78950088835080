import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import styled, { keyframes } from 'styled-components';
import DynamicThankyou from '../../components/ThankyouPages/DynamicThankyou';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';
import adbutlerZones from '../../utils/adbutlerZones';
import { capitalize } from '../../utils/Validation/characterValidation';
import { telThankyouTest } from '../../utils/adButlerTests';

// prettier-ignore
const noDataAdIds = ['483835', '483836', '483837', '483838', '483839', '483840', '483841', '483842'];
// prettier-ignore
const testZones = ['552498', '552499', '552500', '552501', '552503', '552504', '552506', '552507'];

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  75%{
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const TelContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  text-align: center;
  margin: 0;
  font-family: 'Poppins', sans-serif;
`;

const HeaderBlock = styled.div`
  width: 100%;
  background: #0c3a80;
  color: #fff;
  padding: 20px;
  animation: ${fadeIn} 1s ease-in-out;
  .fName {
    font-size: clamp(26px, 5vw, 60px);
    font-weight: 600;
    margin: 0;
  }
  .message {
    font-size: clamp(16px, 3.5vw, 42px);
    font-weight: 400;
    margin: 0;
  }
`;

const CheckmarkContainer = styled.div`
  width: 100px;
  margin: 0 auto;
  img {
    margin-bottom: 0;
  }
`;

const SharedContainer = styled.div`
  display: flex;
  justify-content: space-around;
  animation: ${fadeIn} 1s ease-in-out;
  max-width: 720px;
  margin: 0 auto;
  padding: 20px 0 0;
  .logoContainer {
    width: 100%;
    max-width: 300px;
    margin: 0 10px;
  }
`;

const CongratsBlock = styled.div`
  width: 100%;
  border-top: 3px solid #000;
  animation: ${fadeIn} 1s ease-in-out;

  .congrats {
    font-size: clamp(22px, 3.5vw, 32px);
    font-weight: 600;
    margin: 10px 0 0;
    
  }
  .savings {
    font-size: clamp(18px, 2.5vw, 24px);
    // animation: ${fadeIn} 1.5s ease-in-out;
  }
`;

const TelThankyou = ({
  pageContext: { name = 'tel-thankyou' },
  config,
  path,
}) => {
  const [adIds, setAdIds] = useState([]);
  const [params, setParams] = useState({});
  const [tyType, setTyType] = useState('');
  const [firstName, setFirstName] = useState('');
  const [isILX, setIsILX] = useState(true);

  useEffect(() => {
    const query = parse(window.location.search);
    const loanPurpose = capitalize(query.loan_purpose);
    const formData = JSON.parse(sessionStorage.getItem('formData'));

    setFirstName(formData?.first_name);
    setParams(query);
    let creditRating;
    const goodCreditArr = ['Excellent', 'Good', 'Average'];
    if (goodCreditArr.includes(capitalize(query.credit_rating))) {
      creditRating = 'GoodCredit';
    } else {
      creditRating = 'BadCredit';
    }

    setTyType(`${loanPurpose}${creditRating}`);

    const adObject = adbutlerZones('tel-thankyou', false, params, config);

    if (tyType === 'RefinanceGoodCredit') {
      setAdIds(testZones);
      console.log('tel-GCR to GCR');
    } else if (adObject[tyType]) {
      setAdIds(adObject[tyType]);
    } else {
      setAdIds(adObject.default);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (params.tyPageVersion) {
      telThankyouTest(params, config);
    }

    // eslint-disable-next-line
  }, [params.tyPageVersion]);

  return (
    <TelContainer>
      {tyType !== 'RefinanceGoodCredit' && (
        <>
          <HeaderBlock>
            <CheckmarkContainer>
              <img
                src="https://s3.amazonaws.com/sc-dba.com/lmbx-ty/images/animatedCheckboxSmall.gif"
                alt="Checkmark"
              />
            </CheckmarkContainer>

            <p className="fName">{firstName || 'Thank you'},</p>
            <p className="message">Our experts below will contact you soon!</p>
          </HeaderBlock>

          <SharedContainer>
            <div className="logoContainer">
              <img
                src="https://s3.amazonaws.com/sc-dba.com/lmbx-ty/images/quickenloans.png"
                alt="Quicken Loans"
              />
            </div>
            <div className="logoContainer">
              <img
                src="https://s3.amazonaws.com/sc-dba.com/lmbx-ty/images/loan-depot-logo.png"
                alt="Loan Depot"
              />
            </div>
          </SharedContainer>
        </>
      )}
      {name !== 'sl-thankyou' && (
        <>
          {tyType !== 'RefinanceGoodCredit' && (
            <CongratsBlock>
              <p className="congrats">Congratulations</p>
              <p className="savings">You're eligible for more savings:</p>
            </CongratsBlock>
          )}
          {!isILX && (
            <DynamicThankyou
              adButlerIds={adIds}
              adButlerAcctId="169214"
              params={params}
              config={config}
              path={path}
            />
          )}
        </>
      )}
    </TelContainer>
  );
};

TelThankyou.propTypes = {
  path: PropTypes.string,
  pageContext: PropTypes.object,
  config: PropTypes.object,
};

export default withThankyouWrapper(TelThankyou);
